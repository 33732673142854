import React from "react";

export default function Cards() {
  const cards = [
    {
      icon: "assets/img/icon/awards1.svg",
      title: "Award-Winning Excellence",
      description: "Recognized globally for our outstanding contributions, earning numerous awards for innovation and quality.",
    },
    {
      icon: "assets/img/icon/client2.svg",
      title: "Client-Centric Approach",
      description: "We prioritize our clients' needs, delivering personalized solutions with a commitment to excellence.",
    },
    {
      icon: "assets/img/icon/eteam3.svg",
      title: "Expert Team",
      description: "Our team consists of industry experts with a passion for innovation and a commitment to delivering top-quality results.",
    },
    {
      icon: "assets/img/icon/innovation4.svg",
      title: "Inspired by Japanese Precision & Innovation",
      description: "Developed insights from Japanese scientific research, ensuring meticulous formulation and consistent efficacy.",
    },
  ];

  return (
    <div className="row">
      {cards.map((card, index) => (
        <div className="col-lg-6 col-md-6 wow fadeInUp pt-3 pb-3" data-wow-delay=".2s" key={index}>
          <div className="custom-card">
            <div className="icon">
              <img src={card.icon} alt="icon" />
            </div>
            <div className="content">
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
