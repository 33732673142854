import { useState } from "react";
import { Link } from "react-router-dom";

export default function MobileMenu() {
  const [isAccordion, setIsAccordion] = useState(1);

  const handleAccordion = (key) => {
    setIsAccordion((prevState) => (prevState === key ? null : key));
  };
  return (
    <>
      <div className="mobile-menu fix mb-3 mean-container">
        <div className="mean-bar">
          <Link to="#" className="meanmenu-reveal" style={{ right: 0, left: "auto", display: "inline" }}>
            <span>
              <span>
                <span />
              </span>
            </span>
          </Link>
          <nav className="mean-nav">
            <ul>
              <li className="has-dropdown active menu-thumb">
                <Link to="/">
                  Home
                  <i className="fa-regular fa-plus" />
                </Link>
                <a className="mean-expand" onClick={() => handleAccordion(1)} style={{ fontSize: 18 }}>
                  <i className="far fa-plus" />
                </a>
              </li>
              <li className="has-dropdown active d-xl-none">
                <Link to="#" className="border-none">
                  Home
                  <i className="fa-regular fa-plus" />
                </Link>
                <a className="mean-expand" onClick={() => handleAccordion(2)} style={{ fontSize: 18 }}>
                  {/* <i className="far fa-plus" /> */}
                </a>
              </li>
              <li>
                <Link to="#">About Us</Link>
              </li>
              <li className="has-dropdown">
                <Link to="#">
                  Our Offerings
                  <i className="fa-regular fa-plus" />
                </Link>

                <a className="mean-expand" onClick={() => handleAccordion(3)} style={{ fontSize: 18 }}>
                  {/* <i className="far fa-plus" /> */}
                </a>
              </li>
              <li>
                <Link to="#">
                  Modern Science
                  <i className="fa-regular fa-plus" />
                </Link>
                <a className="mean-expand" onClick={() => handleAccordion(4)} style={{ fontSize: 18 }}>
                  {/* <i className="far fa-plus" /> */}
                </a>
              </li>
              <li>
                <Link to="#">
                  Alviva Edge
                  <i className="fa-regular fa-plus" />
                </Link>
                <a className="mean-expand" onClick={() => handleAccordion(5)} style={{ fontSize: 18 }}>
                  {/* <i className="far fa-plus" /> */}
                </a>
              </li>

              <li className="mean-last">
                <Link to="#">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
