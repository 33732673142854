import Cards from "./Cards.js";

export default function AlvivaEdge() {
  return (
    <>
      <section
        className="achivements-section fix section-bg-2 section-padding bg-cover"
        style={{
          backgroundImage: 'url("assets/img/acheivement-bg.jpg")',
        }}
      >
        <div className="container">
          <div className="achivements-wrapper">
            <div className="row g-5 justify-content-between align-items-center">
              <div className="col-xxl-6 col-xl-7 col-lg-7">
                <div className="achivements-content">
                  <div className="section-title">
                    <h6 className="wow fadeInUp">
                      <i className="fa-regular fa-arrow-left-long" />
                      Alviva Edge
                      <i className="fa-regular fa-arrow-right-long" />
                    </h6>
                    <h2 className="wow fadeInUp" data-wow-delay=".2s">
                      Elevate Your Wellness
                    </h2>
                  </div>
                  <Cards />
                </div>
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-5 wow fadeInUp" data-wow-delay=".2s">
                <div className="achivements-image">
                  <img src="assets/img/acheivement-img-1.jpg" alt="img" />
                  <div className="achivements-image-2 float-bob-y">
                    <img src="assets/img/acheivement-img-2.jpg" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
