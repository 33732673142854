import Layout from "../components/layout/Layout";
import About from "../components/sections/About";
import Hero from "../components/sections/Hero";
import ProductSlider from "../components/sections/ProductSlider";
import AlvivaEdge from "../components/sections/AlvivaEdge";
import AlGallery from "../components/sections/AlGallery";
import AlVideo from "../components/sections/AlVideo";

export default function Home() {
  return (
    <>
      <Layout headerStyle={1} footerStyle={1}>
        <Hero />
        <About />
        <ProductSlider />
        <AlvivaEdge />
        <AlGallery />
        <AlVideo />
      </Layout>
    </>
  );
}
