import { Link } from "react-router-dom";
import Menu from "../Menu";

export default function Header1({ scroll, isMobileMenu, handleMobileMenu, isOffCanvas, handleOffCanvas, isSearch, handleSearch }) {
  return (
    <>
      <header className="header-section-22">
        <div className="header-top-sectionss top-style-2 fix">
          <div className="container-fluid">
            <div className="header-top-wrappers style-2">
              <ul className="contact-list">
                <li>
                  <i className="far fa-envelope" />
                  <Link to="/mailto:info@example.com" className="link">
                    alviva@example.com
                  </Link>
                </li>
                <li>
                  <i className="fa-solid fa-phone-volume" />
                  <Link to="tel:+971504659674">+971 504659674</Link>
                </li>
              </ul>
              <div className="top-right">
                <div className="social-icon d-flex align-items-center">
                  <span>Follow Us |</span>
                  <Link to="/#">
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link to="/#">
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link to="/#">
                    <i className="fa-brands fa-linkedin-in" />
                  </Link>
                  <Link to="/#">
                    <i className="fa-brands fa-youtube" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Divider */}
        <div id="header-sticky" className={`header-1 ${scroll ? "sticky" : ""}`}>
          <div className="main-logo">
            <Link to="/">
              <img src="assets/img/logo/white-logo.svg" alt="logo-image" />
            </Link>
          </div>
          <div className="container-fluid">
            <div className="mega-menu-wrapper">
              <div className="header-main">
                {/* Logo */}
                <div className="logo d-none">
                  <Link to="/" className="header-logo">
                    <img src="assets/img/logo/white-logo.svg" alt="logo-img" />
                  </Link>
                </div>
                {/* Menu */}
                <div className="header-left">
                  <div className="mean__menu-wrapper">
                    <div className="main-menu">
                      <Menu />
                    </div>
                  </div>
                </div>

                {/* Shop Now btn */}
                <div className="header-right d-flex justify-content-end align-items-center">
                  <div className="header-button">
                    <Link to="#" className="theme-btn">
                      Shop Now
                      <i className="fa-solid fa-cart-shopping" />
                    </Link>
                  </div>
                  <div className="header__hamburger d-xl-none my-auto">
                    <div className="sidebar__toggle" type="button" onClick={handleOffCanvas}>
                      {/* <i className="fas fa-bars" /> */}
                      <img src="assets/img/icon/menu-iconw.svg" alt="icon"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
