// import "swiper/css";
import { Link } from "react-router-dom";
import "../../../node_modules/react-modal-video/css/modal-video.css";

import { useState } from "react";
import ModalVideo from "react-modal-video";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const swiperOptions = {
  modules: [Autoplay, Pagination, Navigation],
  slidesPerView: 2,
  spaceBetween: 30,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,

  // Navigation
  navigation: {
    nextEl: ".h1n",
    prevEl: ".h1p",
  },

  // Pagination
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    575: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    767: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    991: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1199: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1350: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  },
};

export default function Hero() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="hero-section hero-1 fix section-padding">
        <video autoPlay loop muted playsInline className="hero-bg-video">
          <source src="/assets/img/hero/home-banner-01.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="container-fluid">
          <div className="row g-4">
            <div className="col-lg-7">
              <div className="hero-content">
                <div className="vector-shape-2">
                  <img src="assets/img/hero/vector-01.svg" alt="img" />
                </div>
                <h1 className="wow fadeInUp" data-wow-delay=".3s">
                  HOW FAR <br />
                  WOULD YOU GO.
                </h1>
                <p className="wow fadeInUp" data-wow-delay=".5s">
                  It is Wellness that defines human beings. Your physical wellness is directly proportional to your mental wellness and vice-versa. This is where Alviva comes into
                  the picture; Making Wellness inclusive and accessible for all sans borders.
                </p>
                <div className="hero-button">
                  <Link to="#" className="theme-btn bg-white wow fadeInUp" data-wow-delay=".3s">
                    Explore More
                    <i className="fa-regular fa-arrow-right" />
                  </Link>
                  <span className="button-text wow fadeInUp" data-wow-delay=".5s">
                    <a onClick={() => setOpen(true)} className="video-btn video-popup">
                      <i className="fa-solid fa-play" />
                    </a>
                    <span className="ms-3 d-line">Play</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalVideo channel="youtube" youtube={{ mute: 0, autoplay: 0 }} isOpen={isOpen} videoId="zkkTE_dIXPs" onClose={() => setOpen(false)} />
    </>
  );
}
