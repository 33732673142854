// import "swiper/css";
import { Link } from "react-router-dom";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const swiperOptions = {
  modules: [Autoplay, Pagination, Navigation],
  spaceBetween: 30,
  speed: 2000,
  loop: true,
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".dot",
    clickable: true,
  },
  navigation: {
    nextEl: ".array-prev",
    prevEl: ".array-next",
  },
  breakpoints: {
    1399: {
      slidesPerView: 4,
    },
    1199: {
      slidesPerView: 3,
    },
    991: {
      slidesPerView: 2,
    },
    767: {
      slidesPerView: 2,
    },
    575: {
      slidesPerView: 1,
    },
    0: {
      slidesPerView: 1,
    },
  },
};

export default function ProductSlider() {
  return (
    <>
      <section className="service-section fix section-padding bg-cover" style={{ backgroundImage: 'url("assets/img/productslider/product-section-bg2.jpg")' }}>
        <div className="container">
          <div className="section-title text-center">
            <h6 className="wow fadeInUp  text-white">
              <i className="fa-regular fa-arrow-left-long" />
              Featured Products
              <i className="fa-regular fa-arrow-right-long" />
            </h6>
            <h2 className="wow fadeInUp text-white" data-wow-delay=".2s">
              Signature Formulations
              <br />
              For Your Wellness
            </h2>
          </div>
          <div className="array-button">
            <button className="array-prev h1p">
              <i className="fa-regular fa-arrow-left-long" />
            </button>
            <button className="array-next h1n">
              <i className="fa-regular fa-arrow-right-long" />
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <div className="swiper service-slider">
            <Swiper {...swiperOptions} className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="service-box-items">
                  <div className="service-thumb">
                    <img src="assets/img/productslider/iCare.png" alt="img" />
                    <div className="icon">
                      <i className="fa-regular fa-cart-arrow-down" />
                    </div>
                  </div>
                  <div className="service-content">
                    <h2 className="number">01</h2>
                    <h3>
                      <Link to="#">iCare</Link>
                    </h3>
                    <p>A unique blend of bioavailable Curcumin for overall women health and well being.</p>
                    <Link to="#" className="link-btn">
                      Explore More <i className="fa-solid fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="service-box-items">
                  <div className="service-thumb">
                    <img src="assets/img/productslider/iCoffee-Creamer.png" alt="img" />
                    <div className="icon">
                      <i className="fa-regular fa-cart-arrow-down" />
                    </div>
                  </div>
                  <div className="service-content">
                    <h2 className="number">02</h2>
                    <h3>
                      <Link to="#">iCoffee</Link>
                    </h3>
                    <p>Replace your regular coffee with iCoffee, Manage blood sugar level in healthy way.</p>
                    <Link to="#" className="link-btn">
                      Explore More <i className="fa-solid fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="service-box-items">
                  <div className="service-thumb">
                    <img src="assets/img/productslider/iCoffee-Black.png" alt="img" />
                    <div className="icon">
                      <i className="fas fa-cart-arrow-down" />
                    </div>
                  </div>
                  <div className="service-content">
                    <h2 className="number">03</h2>
                    <h3>
                      <Link to="#">iCoffee</Link>
                    </h3>
                    <p>A unique blend of bioavailable Curcumin for overall women health and well being. </p>
                    <Link to="#" className="link-btn">
                      Explore More <i className="fa-solid fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="service-box-items">
                  <div className="service-thumb">
                    <img src="assets/img/productslider/iPulse.png" alt="img" />
                    <div className="icon">
                      <i className="fas fa-cart-arrow-down" />
                    </div>
                  </div>
                  <div className="service-content">
                    <h2 className="number">04</h2>
                    <h3>
                      <Link to="#">iPulse</Link>
                    </h3>
                    <p>Antioxidant fruit in premium juice form, which supports health and well-being.</p>
                    <Link to="#" className="link-btn">
                      Explore More <i className="fa-solid fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <div className="service-box-items">
                  <div className="service-thumb">
                    <img src="assets/img/productslider/iCare.png" alt="img" />
                    <div className="icon">
                      <i className="fa-regular fa-cart-arrow-down" />
                    </div>
                  </div>
                  <div className="service-content">
                    <h2 className="number">05</h2>
                    <h3>
                      <Link to="#">iCare</Link>
                    </h3>
                    <p>A unique blend of bioavailable Curcumin for overall women health and well being.</p>
                    <Link to="#" className="link-btn">
                      Explore More <i className="fa-solid fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
