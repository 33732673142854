import Route from './mainRouter'

function App() {
    return (
        <>
            <Route />
        </>
    )
}

export default App