import { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";
export default function About() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-section fix section-padding" id="about">
        {/* <div className="about-shape-1 float-bob-x">
          <img src="assets/img/about/product-decor-02.png" alt="img" />
        </div> */}
        <div className="container">
          <div className="about-wrapper">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="about-image">
                  <img src="assets/img/about/about-alviva.jpg" alt="img" className="wow fadeInLeft" data-wow-delay=".2s" />
                  <div className="about-image-2 wow fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/about/sm-about-img.jpg" alt="img" />
                    <div className="video-box">
                      <a onClick={() => setOpen(true)} className="video-btn video-popup">
                        <i className="fas fa-play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <div className="section-title">
                    <h6 className="wow fadeInUp">
                      <i className="fa-regular fa-arrow-left-long" />
                      about us
                      <i className="fa-regular fa-arrow-right-long" />
                    </h6>
                    <h2 className="wow fadeInUp" data-wow-delay=".2s">
                      Our Essence in a Nutshell
                    </h2>
                  </div>
                  <p className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".4s">
                    Alviva Food Supplements Trading is a next-generation nutraceutical and R&D company, pioneering the future of wellness through scientific precision and Japanese
                    excellence. Our approach is rooted in Japan’s world-renowned healthcare advancements, where science meets nature to create elite, high-performance wellness
                    products backed by cutting-edge research.
                  </p>
                  <ul className="list-items wow fadeInUp" data-wow-delay=".2s">
                    <li>
                      {" "}
                      <i className="fa-solid fa-circle-check" />
                      Highest standards from sourcing pure ingredients to manufacturing.
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-circle-check" />
                      Developed with insights from Japanese scientific research.
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-circle-check" />
                      Compositions aligned with global Wellness trends.
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-circle-check" />
                      Formulated to achieve up to 95% efficacy with 100% safety.
                    </li>
                  </ul>
                  <div className="about-btn-container">
                    <Link to="#" className="theme-btn wow fadeInUp" data-wow-delay=".2s">
                      Know More <i className="fa-regular fa-arrow-right" />
                    </Link>

                    <Link to="#" className="theme-btn2 wow fadeInUp" data-wow-delay=".2s">
                      START YOUR JOURNEY <i className="fa-regular fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="zkkTE_dIXPs" onClose={() => setOpen(false)} />
    </>
  );
}
