import { Link } from "react-router-dom";
import Gallery from "../../elements/Gallery";

export default function Footer1() {
  return (
    <>
      <footer className="footer-section bg-cover bg-cover" style={{ backgroundImage: 'url("assets/img/footer/bg.jpg")' }}>
        <div className="container">
          <div className="contact-info-area">
            <Link to="/" className="logo-img wow fadeInUp" data-wow-delay=".2s">
              <img src="assets/img/logo/white-logo.svg" alt="img" />
            </Link>
            <div className="contact-info-items wow fadeInUp" data-wow-delay=".4s">
              <div className="icon">
                <i className="fa-sharp fa-solid fa-location-dot" />
              </div>
              <div className="content">
                <p>Office location</p>
                <h3>Dubai</h3>
              </div>
            </div>
            <div className="contact-info-items wow fadeInUp" data-wow-delay=".6s">
              <div className="icon">
                <i className="fa-solid fa-envelope" />
              </div>
              <div className="content">
                <p>send email</p>
                <h3>
                  <Link to="/mailto:example@gmail.com">alviva@gmail.com</Link>
                </h3>
              </div>
            </div>
            <div className="contact-info-items wow fadeInUp" data-wow-delay=".8s">
              <div className="icon">
                <i className="fa-solid fa-phone-volume" />
              </div>
              <div className="content">
                <p>Contact</p>
                <h3>
                  <a href="tel:+971504659674"> ‪+971 504659674</a>
                </h3>
              </div>
            </div>
          </div>
          <div className="footer-widgets-wrapper">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h5>About Company</h5>
                  </div>
                  <div className="footer-content">
                    <p>
                      Alviva Food Supplements Trading is a next-generation nutraceutical and R&D company, pioneering the future of wellness through scientific precision and
                      Japanese excellence.
                    </p>
                    <div className="social-icon d-flex align-items-center">
                      <Link to="/#">
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link to="/#">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link to="/#">
                        <i className="fab fa-youtube" />
                      </Link>
                      <Link to="/#">
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h5>Quick Link</h5>
                  </div>
                  <ul className="list-area">
                    <li>
                      <Link to="#">
                        <i className="fa-solid fa-chevrons-right" />
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-solid fa-chevrons-right" />
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-solid fa-chevrons-right" />
                        Login / Register
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 ps-lg-5 wow fadeInUp" data-wow-delay=".6s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h5>Our Services</h5>
                  </div>
                  <ul className="list-area">
                    <li>
                      <Link to="#">
                        <i className="fa-solid fa-chevrons-right" />
                        What We Do
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-solid fa-chevrons-right" />
                        About Our Product
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 ps-lg-5 wow fadeInUp" data-wow-delay=".8s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h5>Instagram</h5>
                  </div>
                  <div className="footer-gallery">
                    <div className="gallery-wrap">
                      <Gallery />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>
              © All Copyright {new Date().getFullYear()} by <Link to="/">Alviva</Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
