export default function AlVideo() {
  return (
    <section className="fix section-padding">
      <div className="container">
        <div className="section-title text-center">
          <h6 className="wow fadeInUp">
            <i className="fa-regular fa-arrow-left-long" />
            Quality at Every Step
            <i className="fa-regular fa-arrow-right-long" />
          </h6>
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            Manufacturing & Packing
          </h2>
        </div>
        <video loop autoPlay muted id="video-player">
          <source src="https://res.cloudinary.com/prasanthindusviva/video/upload/v1743669226/production-video-converted_zzhcxc.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
}
